import '@unicaiot/unica-iot-gallery-airinsight/dist/styles/base.scss'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/web/url-search-params'

import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter as Router, Route } from 'react-router-dom'
import { RootView } from './RootView'
import { LocaleProvider } from '@unicaiot/unica-iot-gallery-core'
import { config } from './config'

const App: React.FunctionComponent = () => {
    return (
        <LocaleProvider
            currentLocale={config.defaultLocale}
            getCatalog={language => {
                return require(/* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
                `./locales/${language}/messages`)
            }}
        >
            <Router>
                <Route path="/" component={RootView} />
            </Router>
        </LocaleProvider>
    )
}

ReactDOM.render(<App />, document.getElementById('root'))
