import { config } from '../../../config'
import { client, ResponsePromise } from '@unicaiot/unica-iot-gallery-core'
import { Building, Mapping, Registration, Room, Sensor } from './types'

export interface IRegistrationService {
    getMapping: (id: string) => ResponsePromise<Mapping>
    getRegistration: (id: string) => ResponsePromise<Registration>
    postRoom: (room: Room) => ResponsePromise
    postBuilding: (building: Building) => ResponsePromise
    putSensor: (sensor: Sensor) => ResponsePromise
}

export const registrationService: IRegistrationService = {
    getMapping(id: string) {
        return client.get<Mapping>(config.mapping(id))
    },
    getRegistration(id: string) {
        return client.get<Registration>(config.registration(id))
    },
    postRoom(room: Room) {
        return client.post(config.rooms, {
            id: room.id,
            name: room.name,
            buildingRef: room.buildingRef,
            roomRef: room.roomRef,
            type: room.type,
        })
    },
    postBuilding(building: Building) {
        return client.post(config.buildings, { id: building.id, name: building.name })
    },
    putSensor(sensor: Sensor) {
        return client.put(config.sensors(sensor.id), { buildingRef: sensor.buildingRef, roomRef: sensor.roomRef })
    },
}
