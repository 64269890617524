export enum PointKpi {
    Sufficient = 'Sufficient',
    Moderate = 'Moderate',
    InSufficient = 'InSufficient',
    Unknown = 'Unknown',
}

export interface IEntity {
    id: string
    name: string
}

export interface Building extends IEntity {
    rooms?: Room[]
    averageKpi: LatestValue
}

export interface Room extends IEntity {
    sensors?: Sensor[]
    averageKpi: LatestValue
    rooms?: Room[]
}

export type Kpi = {
    [key in keyof typeof PointKpi]: number
}

export interface Sensor {
    name: string
    latestKpi: LatestValue
}

export interface LatestValue {
    kpi: PointKpi
    value: number
    time: string
}

export enum Type {
    CO2 = 'co2',
    Temperature = 'temperature',
    Humidity = 'humidity',
}
