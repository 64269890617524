import React, { FunctionComponent, useContext, useMemo, useState } from 'react'
import { registrationService } from '../../services/service'
import styles from './AddNodeModal.module.scss'
import { v4 as uuidv4 } from 'uuid'
import { Button, ButtonStyleType, Icons, Input, LayoutType, Select } from '@unicaiot/unica-iot-gallery-airinsight'
import { LocaleContext, pathof } from '@unicaiot/unica-iot-gallery-core'
import { translations } from '../../../../translations'

export interface NodeModal {
    id: string
    name: string
}

export enum NodeModalType {
    Building = 'building',
    Room = 'room',
}

export interface Props {
    type: NodeModalType
    onSuccess?: (node: NodeModal) => void
    buildingRef?: string
    parentRef?: string
}

const typesMap = new Map<NodeModalType, [string[], number]>()
typesMap.set(NodeModalType.Room, [['floor', 'room', 'space'], 1])

export const AddNodeModal: FunctionComponent<Props> = ({ type, onSuccess, buildingRef, parentRef }) => {
    const locale = useContext(LocaleContext)

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState<string>()

    const [name, setName] = useState<string>('')

    const nameTypes = useMemo(() => typesMap.get(type), [type])
    const [nameType, setNameType] = useState<string | undefined>(nameTypes?.[0][nameTypes?.[1]])

    const handleOnSubmit = async () => {
        try {
            if (!name || (type === NodeModalType.Room && !buildingRef)) {
                return
            }

            setLoading(true)
            setMessage('')

            let result

            const id = uuidv4()

            switch (type) {
                case NodeModalType.Building:
                    result = await registrationService.postBuilding({ id, name })
                    break
                case NodeModalType.Room:
                    result = await registrationService.postRoom({
                        id,
                        name,
                        buildingRef: buildingRef!,
                        roomRef: parentRef,
                        type: nameType!,
                    })
                    break
                default:
                    throw new Error(`${type} is not supported.`)
            }

            setLoading(false)

            if (result.status >= 200 && result.status < 300) {
                onSuccess && onSuccess({ id, name })
            } else {
                setMessage(result.statusText)
            }
        } catch (e: any) {
            setLoading(false)
            setMessage(e.message)
        }
    }

    return (
        <div className={styles.container}>
            <div>
                <Input
                    value={name}
                    required={true}
                    placeholder={locale._(`registrationView.${type.toLowerCase()}Name`)}
                    onChange={e => setName(e)}
                />
                {nameTypes && (
                    <Select
                        layoutType={LayoutType.dark}
                        value={nameType}
                        required={true}
                        placeholder={locale._(
                            pathof<typeof translations>()._('registrationView')._('roomTypeSelect').path
                        )}
                        keyPref={'roomType'}
                        onChange={v => {
                            setNameType(v)
                        }}
                        options={nameTypes[0].map(t => {
                            return { value: t, name: locale._(`registrationView.${t}`) }
                        })}
                    />
                )}
                <Button
                    isDisabled={!name || (type === NodeModalType.Room && !buildingRef)}
                    className={styles.createButton}
                    buttonStyle={ButtonStyleType.primary}
                    onClick={handleOnSubmit}
                    loading={loading}
                    icon={Icons.create}
                >
                    Create
                </Button>
                {message && <div className={styles.message}>{message}</div>}
            </div>
        </div>
    )
}
