import React, { Fragment, FunctionComponent, useContext } from 'react'
import styles from './Explorer.module.scss'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
    LocaleContext,
    PageError,
} from '@unicaiot/unica-iot-gallery-core'
import {
    TreeView,
    QuestionMark,
    InfoText,
    TextWeightEnumType,
    TextSizeEnumType,
    TextColorEnumType,
    TreeNode,
} from '@unicaiot/unica-iot-gallery-airinsight'
import dateFormat from 'dateformat'
import { isMobile } from 'react-device-detect'
import { PointKpi, Room, Type } from '../../services/types'
import { useGetOverview } from '../../services/hooks'
import legend from '../../Common/Legent'
import { Trans } from '@lingui/react'
import { translations } from '../../../../translations'
import units from '../../Common/Units'
import trunc from '../../Common/Trunc'

export const Explorer: FunctionComponent = () => {
    const locale = useContext(LocaleContext)

    const color = (kpi?: PointKpi) => {
        return kpi ? legend.get(kpi) : TextColorEnumType.white
    }

    const result = useGetOverview()

    if (result.error) {
        throw new PageError(result.error)
    }

    const expand = (i: string, rooms?: Room[]): TreeNode[] => {
        return (
            rooms?.map((room, j) => {
                return {
                    node: (
                        <Fragment>
                            <InfoText>{room.name}</InfoText>
                            <InfoText
                                color={color(room.averageKpi?.kpi)}
                                weight={TextWeightEnumType.bold}
                                className={styles.avg}
                            >
                                {
                                    <Fragment>
                                        &#8777; {room.averageKpi?.value?.toFixed(trunc.get(Type.CO2)) || 'n/a'}{' '}
                                        {units.get(Type.CO2)}
                                    </Fragment>
                                }
                            </InfoText>
                        </Fragment>
                    ),
                    loading: false,
                    active: true,
                    children: expand(`${i}-${j}`, room?.rooms).concat(
                        room?.sensors?.map((sensor, y) => {
                            const infoPoint = (
                                <div className={styles.info}>
                                    <InfoText color={TextColorEnumType.grey} size={TextSizeEnumType.medium}>
                                        {sensor.latestKpi?.time
                                            ? locale._(translations.dataExplorer.timeValue, {
                                                  date: dateFormat(sensor.latestKpi?.time, 'd mmmm yyyy').toLowerCase(),
                                                  time: dateFormat(sensor.latestKpi?.time, 'HH:MM'),
                                              })
                                            : 'n/a'}
                                        &nbsp;
                                    </InfoText>
                                    <InfoText color={color(sensor.latestKpi?.kpi)} weight={TextWeightEnumType.bold}>
                                        {sensor.latestKpi?.value || 'n/a'} {units.get(Type.CO2)}
                                    </InfoText>
                                </div>
                            )
                            return {
                                node: (
                                    <Fragment>
                                        <InfoText size={TextSizeEnumType.medium} className={styles.name}>
                                            {sensor.name?.split('-')?.[0]}
                                        </InfoText>
                                        {isMobile ? (
                                            <div className={styles.qm} onClick={e => e.stopPropagation()}>
                                                <QuestionMark id={`${i}-${j}-${y}`}>{infoPoint}</QuestionMark>
                                            </div>
                                        ) : (
                                            <div className={styles.time}>{infoPoint}</div>
                                        )}
                                    </Fragment>
                                ),
                                loading: false,
                                active: false,
                                children: null,
                            }
                        }) || []
                    ),
                }
            }) || []
        )
    }

    return (
        <Fragment>
            {result.loading ? (
                <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
            ) : (
                <TreeView
                    root={
                        <InfoText
                            className={styles.root}
                            size={TextSizeEnumType.large}
                            weight={TextWeightEnumType.bold}
                        >
                            <Trans id="buildingsOverview.title" message="Gebouwen" />
                        </InfoText>
                    }
                    nodes={(result.data || []).map((building, i) => {
                        return {
                            node: (
                                <Fragment>
                                    <InfoText> {building.name}</InfoText>
                                    <InfoText
                                        color={color(building.averageKpi?.kpi)}
                                        weight={TextWeightEnumType.bold}
                                        className={styles.avg}
                                    >
                                        {
                                            <Fragment>
                                                &#8777;{' '}
                                                {building.averageKpi?.value?.toFixed(trunc.get(Type.CO2)) || 'n/a'}{' '}
                                                {units.get(Type.CO2)}
                                            </Fragment>
                                        }
                                    </InfoText>
                                </Fragment>
                            ),
                            loading: false,
                            active: true,
                            children: expand(`${i}`, building.rooms),
                        }
                    })}
                />
            )}
        </Fragment>
    )
}
