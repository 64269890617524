import { Building, Kpi } from './types'
import { hsService } from './service'
import { RequestStatus, useRequest } from '@unicaiot/unica-iot-gallery-core'

export const useGetOverviewAllCounts = (): RequestStatus<Kpi | undefined> => {
    return useRequest(hsService.getOverviewAllCounts, [])
}

export const useGetOverview = (): RequestStatus<Building[] | undefined> => {
    return useRequest(hsService.getOverview, [])
}
