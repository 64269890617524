import { Config as ConfigGallery, config as configGallery } from '@unicaiot/unica-iot-gallery-core'

export interface Config extends ConfigGallery {
    mapping: (code: string) => string
    registration: (id: string) => string
    rooms: string
    floors: string
    spaces: string
    kpi: {
        getAllOverviewCounts: string
        getAllOverview: string
    }
    buildings: string
    sensors: (id: string) => string
    hdata: {
        get: string
    }
    defaultLocale: string
}

export const config: Config = {
    ...configGallery,
    mapping: (code: string) => `/mapping/co2/${code}`,
    registration: (id: string) => `/api/registration/${id}`,
    rooms: '/api/rooms',
    floors: '/api/floors',
    spaces: '/api/spaces',
    kpi: {
        getAllOverviewCounts: 'api/kpi/counts',
        getAllOverview: 'api/kpi/all',
    },
    buildings: 'api/buildings',
    sensors: (id: string) => `/api/sensors/${id}`,
    hdata: {
        get: '/hs/hisRead',
    },
    defaultLocale: 'nl',
}
