import classNames from 'classnames'
import React, { FunctionComponent, useContext } from 'react'
import styles from './AddNodeButton.module.scss'
import { AddNodeModal, NodeModal, NodeModalType } from '../AddNodeModal/AddNodeModal'
import { ButtonStyleType, TextButton, ModalManager, ModalView } from '@unicaiot/unica-iot-gallery-airinsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'

interface Props {
    type: NodeModalType
    parentRef?: string
    buildingRef?: string
    disabled?: boolean
    onSuccess?: (node: NodeModal) => void
}

export const AddNodeButton: FunctionComponent<Props> = ({
    type,
    parentRef,
    buildingRef,
    disabled = false,
    onSuccess,
}) => {
    const locale = useContext(LocaleContext)

    const onSubmitInsternal = (item: NodeModal, closeModel?: () => void) => {
        onSuccess && onSuccess(item)
        closeModel && closeModel()
    }

    return (
        <ModalManager
            showHeader={true}
            render={openModal => (
                // eslint-disable-next-line
                <div onClick={e => e.preventDefault()} className={classNames(styles.container)}>
                    <TextButton
                        className={classNames(styles.buttom, {
                            [styles.isDisable]: disabled,
                        })}
                        buttonStyle={ButtonStyleType.secondary}
                        onClick={openModal}
                    >
                        {locale._(`registrationView.${type.toLowerCase()}Add`)}
                    </TextButton>
                </div>
            )}
            renderModal={closeModal => (
                <ModalView requestClose={closeModal}>
                    <AddNodeModal
                        parentRef={parentRef}
                        buildingRef={buildingRef}
                        type={type}
                        onSuccess={item => {
                            onSubmitInsternal(item, closeModal)
                        }}
                    />
                </ModalView>
            )}
        />
    )
}
