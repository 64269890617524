import { defineMessage } from '@lingui/macro'

export const translations = {
    login: {
        reset: defineMessage({ id: 'login.reset', message: 'Resetten' }),
        signIn: defineMessage({ id: 'login.signIn', message: 'Inloggen' }),
        change: defineMessage({ id: 'login.change', message: 'Wijzig' }),
        emailPlaceholder: defineMessage({ id: 'login.emailPlaceholder', message: 'E-mail' }),
        passwordPlaceholder: defineMessage({ id: 'login.passwordPlaceholder', message: 'Wachtwoord' }),
        confirmPasswordPlaceholder: defineMessage({
            id: 'login.confirmPasswordPlaceholder',
            message: 'Bevestig wachtwoord',
        }),
        forget: defineMessage({ id: 'login.forget', message: 'Wachtwoord vergeten?' }),
        back: defineMessage({ id: 'login.back', message: 'Terug' }),
        emailSendTitle: defineMessage({
            id: 'login.emailSendTitle',
            message: 'We hebben zojuist een e-mail verstuurd met een link om uw wachtwoord te wijzigen.',
        }),
        emailSendSubtitle: defineMessage({
            id: 'login.emailSendSubtitle',
            message:
                'Het kan een paar minuten duren voordat u deze mail ontvangt. Controleer uw spamfolder als er geen e-mail van ons in uw inbox verschijnt.',
        }),
    },
    errorView: {
        backToDefault: defineMessage({ id: 'errorView.backToDefault', message: 'Ga terug naar de standaard pagina' }),
    },
    errorLayout: {
        title: defineMessage({ id: 'ErrorLayout.title', message: 'Oeps, er is iets fout gegaan.' }),
    },
    dataExplorer: {
        title: defineMessage({ id: 'dataExplorer.title', message: 'Het overzicht' }),
        timeValue: defineMessage({ id: 'dataOverview.timeValue', message: 'op {date} om {time}' }),
    },
    registrationView: {
        title: defineMessage({ id: 'registrationView.title', message: 'Sensor activeren' }),
        buildingSelect: defineMessage({ id: 'registrationView.buildingSelect', message: 'Selecteer een gebouw' }),
        buildingAdd: defineMessage({ id: 'registrationView.buildingAdd', message: '+ Een gebouw toevoegen' }),
        buildingName: defineMessage({ id: 'registrationView.buildingName', message: 'Vul uw gebouwnaam in' }),
        roomName: defineMessage({ id: 'registrationView.roomName', message: 'Vul uw gebiednaam in' }),
        roomTypeSelect: defineMessage({ id: 'registrationView.roomTypeSelect', message: 'Selecteer een gebiedtype' }),
        roomSelect: defineMessage({ id: 'registrationView.roomSelect', message: 'Selecteer een gebied' }),
        roomNone: defineMessage({ id: 'registrationView.roomNone', message: 'Geen gebied' }),
        roomAdd: defineMessage({ id: 'registrationView.roomAdd', message: '+ Een gebied toevoegen' }),
        floor: defineMessage({ id: 'registrationView.floor', message: 'Verdieping' }),
        room: defineMessage({ id: 'registrationView.room', message: 'Ruimte' }),
        space: defineMessage({ id: 'registrationView.space', message: 'Gebied' }),
    },
    resetPasswordWidget: {
        title: defineMessage({ id: 'resetPasswordWidget.title', message: 'Wachtwoord' }),
        subTitle: defineMessage({
            id: 'resetPasswordWidget.subTitle',
            message: 'U kunt hier uw wachtwoord opnieuw instellen. De link wordt u per e-mail toegestuurd.',
        }),
        reset: defineMessage({ id: 'resetPasswordWidget.reset', message: 'Resetten' }),
        successMessage: defineMessage({
            id: 'resetPasswordWidget.successMessage',
            message: 'Uw aanvraag is succesvol ingediend. Bekijk je emails.',
        }),
    },
    profile: {
        profile: defineMessage({ id: 'profile.profile', message: 'Profiel' }),
        help: defineMessage({ id: 'profile.help', message: 'Hulp' }),
        logout: defineMessage({ id: 'profile.logout', message: 'Uitloggen' }),
    },
    iconsWidget: {
        title: defineMessage({ id: 'iconsWidget.title', message: 'Iconen' }),
        subTitle: defineMessage({
            id: 'iconsWidget.subTitle',
            message: 'Hier vind je de betekenis van de verschillende iconen.',
        }),
        info: defineMessage({ id: 'iconsWidget.info', message: 'Hulp' }),
        profile: defineMessage({ id: 'iconsWidget.profile', message: 'Profiel' }),
        logout: defineMessage({ id: 'iconsWidget.logout', message: 'Uitloggen' }),
        full: defineMessage({ id: 'iconsWidget.full', message: 'Vergroten' }),
        export: defineMessage({ id: 'iconsWidget.export', message: 'Exportpagina' }),
        pulse: defineMessage({ id: 'iconsWidget.pulse', message: 'Klikbare actie' }),
        close: defineMessage({ id: 'iconsWidget.close', message: 'Sluiten' }),
        compare: defineMessage({ id: 'iconsWidget.compare', message: 'Vergelijken' }),
        reset: defineMessage({ id: 'iconsWidget.reset', message: 'Resetten' }),
        excel: defineMessage({ id: 'iconsWidget.excel', message: 'Exporteren' }),
        compareAdd: defineMessage({ id: 'iconsWidget.compareAdd', message: 'Voeg toe aan vergelijking' }),
        compareRemove: defineMessage({ id: 'iconsWidget.compareRemove', message: 'Verwijder uit vergelijking' }),
    },
}
