import React, { FunctionComponent } from 'react'
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { config } from './config'
import { routes } from './routes'
import {
    TelemetryProvider,
    RedirectorTemplate,
    Shield,
    withLogin,
    useResize,
    useScroll,
} from '@unicaiot/unica-iot-gallery-core'
import { GlobalErrorView, LoginView, ResetPasswordView } from '@unicaiot/unica-iot-gallery-airinsight'
import { RegistrationView } from './domain/Registration/Views/RegistrationView'
import { DataExplorer } from './domain/DataExplorer/Views/DataExplorer'

withLogin()

export const Redirector: FunctionComponent<RouteComponentProps> = props => {
    return (
        <RedirectorTemplate
            {...props}
            getRedirector={params => {
                let path = config.defaultPath

                if (params.c) {
                    path = routes.registration(`${params.c}`)
                }

                return <Redirect to={path} />
            }}
        />
    )
}

const TelemetryProviderWithRouter = withRouter(TelemetryProvider)

export const RootView: FunctionComponent = () => {
    useScroll()

    useResize()

    return (
        <Shield
            getErrorView={() => {
                return <GlobalErrorView />
            }}
        >
            <TelemetryProviderWithRouter instrumentationKey={config.instrumentationKey}>
                <Switch>
                    <Route exact={true} path={routes.login} component={LoginView} />
                    <Route exact={true} path={routes.resetPassword} component={ResetPasswordView} />
                    <Route exact={true} path={routes.index} component={Redirector} />
                    <Route exact={true} path={routes.explorer} component={DataExplorer} />
                    <Route exact={true} path={routes.registration()} component={RegistrationView} />
                    <Redirect to={config.defaultPath} />
                </Switch>
            </TelemetryProviderWithRouter>
        </Shield>
    )
}
