import { config } from '../../../config'
import { Building, Kpi } from './types'
import { ResponsePromise, client } from '@unicaiot/unica-iot-gallery-core'

export interface IHSService {
    getOverviewAllCounts: () => ResponsePromise<Kpi>
    getOverview: () => ResponsePromise<Building[]>
}

export const hsService: IHSService = {
    getOverviewAllCounts() {
        return client.get<Kpi>(config.kpi.getAllOverviewCounts)
    },
    getOverview() {
        return client.get<Building[]>(config.kpi.getAllOverview)
    },
}
