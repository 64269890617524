import { Auth, PageLayout } from '@unicaiot/unica-iot-gallery-airinsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import React, { FunctionComponent, useContext } from 'react'
import { translations } from '../../../translations'
import { Explorer } from '../components/Explorer/Explorer'

export const DataExplorer: FunctionComponent = () => {
    const locale = useContext(LocaleContext)

    return (
        <Auth>
            <PageLayout
                title={locale._(translations.dataExplorer.title)}
                content={() => <Explorer />}
                showFooter={true}
                showHeader={true}
            />
        </Auth>
    )
}
