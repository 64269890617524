import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
    PageError,
    ActivityIndicator,
    ActivityIndicatorEnumType,
    LocaleContext,
    pathof,
} from '@unicaiot/unica-iot-gallery-core'
import { useGetMapping, useGetRegistration } from '../services/hooks'
import styles from '../Views/RegistrationView.module.scss'
import { Building, Room } from '../services/types'
import { AddNodeButton } from '../components/AddNodeButton/AddNodeButton'
import { NodeModal, NodeModalType } from '../components/AddNodeModal/AddNodeModal'
import { registrationService } from '../services/service'
import {
    PageLayout,
    Auth,
    Button,
    ButtonStyleType,
    LayoutType,
    Icons,
    Select,
    InfoText,
    TextStyleEnumType,
    TextColorEnumType,
    TextSizeEnumType,
} from '@unicaiot/unica-iot-gallery-airinsight'
import { translations } from '../../../translations'
import { config } from '../../../config'
import { Trans } from '@lingui/react'

export interface Params {
    id: string
}

const RegistrationViewLayout: FunctionComponent<Params> = ({ id }) => {
    const locale = useContext(LocaleContext)

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState<string>()
    const [success, setSuccess] = useState<boolean>()

    const [buiding, setBuilding] = useState<Building>()
    const [globalRoom, setRoom] = useState<Room>()

    const [buildings, setBuildings] = useState<Building[]>([])

    const mapRequest = useGetMapping(id)

    if (mapRequest.error) {
        throw new PageError(mapRequest.error)
    }

    const registrationRequest = useGetRegistration(mapRequest.data?.value)

    if (registrationRequest.error) {
        throw new PageError(registrationRequest.error)
    }

    useEffect(() => {
        const findRoom = (rooms?: Room[], roomRef?: string, parentRoom?: Room): Room | undefined => {
            const room =
                rooms?.find(r => r.id === roomRef) ||
                rooms?.map(r => findRoom(r.rooms, roomRef, r))?.filter(r => r)?.[0]

            let result: Room | undefined

            if (room) {
                result = Object.assign({}, room)
                result.rooms = result.rooms || []
            }

            if (parentRoom && result) {
                result.roomRef = parentRoom.id
                result = Object.assign({}, { ...parentRoom, rooms: [result] })
            }

            return result
        }

        if (registrationRequest.data?.sensor?.buildingRef && registrationRequest.data?.sensor?.roomRef) {
            const b = registrationRequest.data.buildings?.find(
                b => b.id === registrationRequest.data?.sensor?.buildingRef
            )
            setBuilding(Object.assign({}, b))
            setRoom(findRoom(b?.rooms, registrationRequest.data?.sensor?.roomRef))
        }
        if (registrationRequest.data?.buildings) {
            setBuildings(registrationRequest.data.buildings)
        }
    }, [registrationRequest.data])

    const handleOnSubmit = async () => {
        try {
            if (!buiding || !globalRoom || !registrationRequest.data?.sensor) {
                return
            }

            const getRoomRef = (room: Room): string => {
                return room.rooms?.[0] ? getRoomRef(room.rooms?.[0]) : room.id
            }

            setLoading(true)
            setMessage('')
            setSuccess(undefined)

            registrationRequest.data.sensor.buildingRef = buiding.id
            registrationRequest.data.sensor.roomRef = getRoomRef(globalRoom)

            const result = await registrationService.putSensor(registrationRequest.data.sensor)

            setLoading(false)

            if (result.status >= 200 && result.status < 300) {
                setSuccess(true)
            } else {
                setSuccess(false)
                setMessage(result.statusText)
            }
        } catch (e: any) {
            setLoading(false)
            setSuccess(false)
            setMessage(e.message)
        }
    }

    const onSuccess = (node: NodeModal, type: NodeModalType, parrentRef?: string) => {
        const push = (newRoom: Room, rooms?: Room[]) => {
            const rs = parrentRef ? rooms?.find(r => r.id === parrentRef)?.rooms : rooms

            if (rs) {
                newRoom.roomRef = parrentRef
                newRoom.rooms = []
                rs.push(newRoom)
            } else {
                rooms?.forEach(r => push(newRoom, r.rooms))
            }
        }

        switch (type) {
            case NodeModalType.Building:
                buildings.push({ id: node.id, name: node.name, rooms: [] })
                setBuildings(Object.assign([], buildings))
                break
            case NodeModalType.Room:
                if (buiding) {
                    const b = buildings.find(b => b.id === buiding.id) || buiding
                    b.rooms = b.rooms || []
                    push({ id: node.id, name: node.name, buildingRef: buiding.id, type }, b.rooms)
                    setBuildings(Object.assign([], buildings))
                    setBuilding(Object.assign({}, b))
                }
                break
            default:
                throw new Error(`${type} is not supported.`)
        }
    }

    const expand = (room?: Room, rooms?: Room[], parrentRoom?: Room) => {
        return (
            <Fragment>
                <Select
                    value={room?.id || ''}
                    disabled={!buiding}
                    required={true}
                    placeholder={
                        parrentRoom
                            ? undefined
                            : locale._(pathof<typeof translations>()._('registrationView')._('roomSelect').path)
                    }
                    keyPref={`room${parrentRoom?.id}`}
                    onChange={v => {
                        const vRoom = rooms?.find(r => r.id === v)
                        let result: Room | undefined = vRoom && { ...vRoom, rooms: [] }

                        if (parrentRoom) {
                            parrentRoom.rooms = result ? [result] : []
                            result = globalRoom
                        }

                        setRoom(Object.assign({}, result))
                    }}
                    options={(parrentRoom
                        ? [
                              {
                                  value: '',
                                  name: locale._(
                                      pathof<typeof translations>()._('registrationView')._('roomNone').path
                                  ),
                              },
                          ]
                        : []
                    ).concat(
                        rooms?.map(t => {
                            return { value: t.id, name: t.name }
                        }) ?? []
                    )}
                />
                <AddNodeButton
                    buildingRef={buiding?.id}
                    parentRef={parrentRoom?.id}
                    disabled={!buiding}
                    type={NodeModalType.Room}
                    onSuccess={n => onSuccess(n, NodeModalType.Room, parrentRoom?.id)}
                />
                {room?.rooms && expand(room.rooms?.[0], rooms?.find(r => r.id === room?.id)?.rooms, room)}
            </Fragment>
        )
    }

    return (
        <PageLayout
            pageType={LayoutType.dark}
            subTitle={locale._(translations.registrationView.title)}
            title={registrationRequest.data?.sensor?.name || ''}
            backRoute={config.defaultPath}
            content={() => (
                <>
                    {mapRequest.loading || registrationRequest.loading ? (
                        <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
                    ) : (
                        <div className={styles.content}>
                            <Select
                                value={buiding?.id}
                                required={true}
                                placeholder={locale._(
                                    pathof<typeof translations>()._('registrationView')._('buildingSelect').path
                                )}
                                keyPref={'building'}
                                onChange={v => {
                                    setBuilding(buildings.find(b => b.id === v))
                                    setRoom(undefined)
                                }}
                                options={buildings.map(t => {
                                    return { value: t.id, name: t.name }
                                })}
                            />
                            <AddNodeButton
                                type={NodeModalType.Building}
                                onSuccess={n => onSuccess(n, NodeModalType.Building)}
                            />
                            {expand(globalRoom, buiding?.rooms)}
                            <Button
                                isDisabled={!buiding || !globalRoom}
                                className={styles.activateButton}
                                buttonStyle={ButtonStyleType.secondary}
                                icon={Icons.activation}
                                onClick={handleOnSubmit}
                                loading={loading}
                            >
                                <Trans id="registrationView.activate" message="Activeren" />
                            </Button>
                            {message && (
                                <InfoText
                                    style={TextStyleEnumType.italic}
                                    color={TextColorEnumType.white}
                                    size={TextSizeEnumType.medium}
                                    className={styles.message}
                                >
                                    {message}
                                </InfoText>
                            )}
                            {success && (
                                <InfoText
                                    style={TextStyleEnumType.italic}
                                    color={TextColorEnumType.white}
                                    size={TextSizeEnumType.medium}
                                    className={styles.message}
                                >
                                    <Trans
                                        id="registrationView.success"
                                        message="De sensor is succesvol geactiveerd. Het kan tot 10 minuten duren voordat de eerste metingen zichtbaar worden."
                                    />
                                </InfoText>
                            )}
                        </div>
                    )}
                </>
            )}
            showFooter={true}
            showHeader={true}
        />
    )
}

export const RegistrationView: FunctionComponent<RouteComponentProps<Params>> = props => {
    return (
        <Auth>
            <RegistrationViewLayout id={props.match.params.id} />
        </Auth>
    )
}
