import { registrationService } from './service'
import { RequestStatus, useRequest } from '@unicaiot/unica-iot-gallery-core'
import { Mapping, Registration } from './types'

export const useGetMapping = (id: string): RequestStatus<Mapping | undefined> => {
    const getProsmise = () => (id ? registrationService.getMapping(id) : undefined)

    return useRequest(getProsmise, [id])
}

export const useGetRegistration = (id?: string): RequestStatus<Registration | undefined> => {
    const getProsmise = () => (id ? registrationService.getRegistration(id) : undefined)

    return useRequest(getProsmise, [id])
}
