import { TextColorEnumType } from '@unicaiot/unica-iot-gallery-airinsight'
import { PointKpi } from '../services/types'

const legend = new Map<PointKpi, TextColorEnumType>()

legend.set(PointKpi.InSufficient, TextColorEnumType.red)
legend.set(PointKpi.Moderate, TextColorEnumType.yellow)
legend.set(PointKpi.Sufficient, TextColorEnumType.green)
legend.set(PointKpi.Unknown, TextColorEnumType.white)

export default legend
